.welcome-page {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.welcome-card {
  max-width: 800px;
  width: 100%;
}

.welcome-text {
  font-size: 1.2rem;
  line-height: 1.6;
  margin: 2rem 0;
}

.features {
  text-align: left;
}

.features ul {
  list-style-type: none;
  padding: 0;
}

.features li {
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
}

.features li:before {
  content: '✓';
  color: var(--primary-color);
  margin-right: 0.5rem;
} 