.chat-box {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  background: var(--surface-card);
  border-radius: var(--border-radius);
  box-shadow: var(--card-shadow);
}

.chat-box .p-card-content {
  padding: 1.5rem;
}

.chat-box .p-inputgroup {
  margin-bottom: 1rem;
}

.chat-box .p-message {
  text-align: left;
  background: var(--surface-overlay);
}

.answer {
  margin-top: 1.5rem;
  padding: 1rem;
  background: var(--surface-overlay);
  border-radius: var(--border-radius);
}