body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: var(--font-family);
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: var(--surface-section);
  color: var(--text-color);
  padding-top: 1rem;
}

.layout-wrapper {
  min-height: 100vh;
  background: var(--surface-section);
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.content-wrapper {
  padding: 2rem;
  flex: 1;
}

.authenticator-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.navbar-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
