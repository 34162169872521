.navbar-container {
  background-color: var(--surface-card);
  border-bottom: 1px solid var(--surface-border);
  width: 100%;
  margin: 0 auto;
}

.navbar-container .p-menubar {
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 0.5rem 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.navbar-end {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.user-email {
  color: var(--text-color-secondary);
  font-size: 0.9rem;
}

.p-menubar .p-menubar-root-list > .p-menuitem {
  margin-right: 0.2rem;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:hover {
  background-color: rgba(0, 255, 0, 0.15);
  box-shadow: 0 0 8px rgba(0, 255, 0, 0.2);
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link.active {
  border-bottom: 2px solid var(--primary-color);
  background-color: rgba(0, 255, 0, 0.1);
} 