.Home {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: var(--text-color);
  background: var(--surface-ground);
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #1e1e1e;
}

h1 {
  font-size: 1.5rem;
  margin: 0;
}

main {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}
.logout-btn {
  background-color: transparent;
  color: var(--text-color);
  border: 2px solid var(--primary-color);
  padding: 10px 20px;
  border-radius: var(--border-radius);
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.logout-btn span {
  position: relative;
  z-index: 1;
}

.logout-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, #00ff00, transparent);
  transition: all 0.4s ease;
}

.logout-btn:hover::before {
  left: 100%;
}

.logout-btn:hover {
  background-color: var(--primary-color);
  color: var(--surface-ground);
  box-shadow: 0 0 10px var(--primary-color);
}

.logout-btn:hover span {
  color: var(--surface-ground);
}
